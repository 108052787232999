import React from 'react'
import FillerServic from './FillerServic'
import FillerService2 from './FillerService2'
import FillerService3 from './FillerService3'
import FillerService4 from './FillerService4'
import Six from '../Home/Six'

const Services = () => {
  return (
    <>
    <FillerServic/>
    <FillerService2/>
    <FillerService3/>
    <FillerService4/>
    <Six/>
   
    
    </>

    )
}

export default Services