import React from 'react'
import One from './One'
import Two from './Two'
import Tree from './Tree'
import Four from './Four'
import Five from './Five'
import Six from './Six'
import Seven from './Seven'
const Home = () => {
  return (

    <>
    <One/>
  
    <Four/>
    <Six/>

    </>


    )
}

export default Home